import React from "react";
import "./Intro.css";
export default function Intro() {
  return (
    <section class="container">
      <h2 class="title">
        <span>I am</span>
      </h2>
      <h1 className="myname"><span>Arunbabu</span></h1>
      <h3 className="title" >
      <span className="myself">and a </span> <span className="role">Full Stack Developer</span>
      </h3>
    </section>
  );
}
